const photoBookReducer = (state = {}, action) => {
	switch (action.type) {
		case 'PHOTO_BOOKS':
			const photoBookArray = action.payload.data;

			const photoBookDetails = photoBookArray.reduce(function(result, item, index, array) {
				result[item.url] = item; //a, b, c
				return result;
			}, {});

			const photoBookData = {
				photoBookList: photoBookArray,
				photoBookDetails: photoBookDetails
			};

			return Object.assign({}, state, {"photoBooks": photoBookData})
		default:
			return state
	}
}

export default photoBookReducer
