import React, { Component } from "react";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPhotoContainers } from '../actions';
import EditPost from "./EditPost";

class ChooseEditPostHome extends Component {
	 constructor(props) {
			super(props);
			this.state = {
				photoContainer: '',
				submit: false
			};
			this.handleChoosePhotoContainer = this.handleChoosePhotoContainer.bind(this);
	 }

	componentDidMount()
	{
		this.props.getPhotoContainers().then(data => {
 		 console.log("Success");
 		 console.log(data);
 	 }).catch(err => {
 		 console.log("err");
 		 console.log(err);
 	 });
	}

	handleChoosePhotoContainer()
	{
		this.setState({'submit': true});
	}

		renderRedirect = () => {
			if (this.state.photoContainer && this.state.submit) {
				// return <Navigate from="/chooseeditpost" to="'/EditPost/' + this.state.photoContainer " />
				return <Navigate to={{
			    pathname: "/EditPost/" + this.state.photoContainer,
					state: { id: this.state.photoContainer }
			  }} />
			}
		}

 	render()
	{
		const allPhotoContainers = this.props.photoContainerArray ? this.props.photoContainerArray : [];
		console.log(allPhotoContainers);
		console.log(this.props)

		return (
						<div>
							<div className="photoTitle">Choose photostory or photogallery</div>
							<div className="photoSubTitle">Add photostory</div>
							<Link to={{pathname: ('/ChooseEditPost/new')}}>
								<button type="button">Submit</button>
							</Link>
							<br /><br />
							<div className="photoSubTitle">Or update photostory or photogallery</div>

								<p className="regText">
									<select onChange={(e)=> this.setState({ photoContainer: e.target.value })}>
									<option value="" selected>All tags</option>
									{allPhotoContainers.map((photoContainer, photoContainerKey) =>
										<option value={photoContainer.url}>{photoContainer.title}</option>
									)}
									</select>
								</p>

								<Link to={{pathname: ('/ChooseEditPost/' + this.state.photoContainer)}}>
									<button type="button">Submit</button>
								</Link>
								&nbsp;
								<br />
						</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		photoContainerArray: state.photoContainerReducer.photoContainerArray
	}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			getPhotoContainers: getPhotoContainers
		}, dispatch);

}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseEditPostHome)
