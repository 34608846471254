import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../lib/withRouter";
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { getHomePage } from '../actions';

class Home extends Component {
	 constructor(props) {
			super(props);
			this.state = {};
	 }

	componentDidMount() {
		this.props.getHomePage().then(data => {
		}).catch(err => {
			console.log("err");
			console.log(err);
		})
	}

	componentDidUpdate(prevProps, prevState)
	{
	}

	render() {
		return (
			<div className="homePageBox">
				<div colSpan="3" className="homeheader">Zion Orent Photography</div>
			{
				this.props.homePage && this.props.homePage.length > 0 ?
					<div className="photoRow">
					{
						this.props.homePage.map((homepage, homepageKey) =>
							<div className="photo-category">
								<Link to={'/' + homepage.id}>
									<img src={homepage.coverPhoto} alt={homepage.name} title={homepage.name} />
									<br /><span>{homepage.name}</span>
								</Link>
							</div>
						)
					}
					</div>
					: ''
			}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
		return {
			homePage: state.homePageReducer.homePage
		}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			getHomePage: getHomePage
		}, dispatch);
}

export default compose(
	withRouter, connect(mapStateToProps, mapDispatchToProps)
)(Home);
