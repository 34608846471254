import React, { Component } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPhotoBooks, closeHamburger } from '../actions';
import ItemRowTable from '../containers/ItemRowTable';
import PhotoBooksHome from "./PhotoBooksHome";
import PhotoBook from "./PhotoBook";

class PhotoBooks extends Component {
	 constructor(props) {
			super(props);
	 }

	componentDidMount() {
		console.log('mountee')
	}

	componentDidUpdate(prevProps, prevState)
	{

	}

 	render() {
		return (
				<Routes>
					<Route path="" element={<PhotoBooksHome />} />
					<Route path=":url" element={<PhotoBook />} />
				</Routes>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
		return {
			photoBooks: state.photoBookReducer.photoBooks,
			hamburger: state.hamburgerReducer.hamburgerState
		}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			closeHamburger: closeHamburger
		}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoBooks)
