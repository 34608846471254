import React, { Component, useEffect, useState  } from 'react';
import { Link } from "react-router-dom";

class ItemRowTable extends Component
{
	constructor(props) {
		 super(props);
		 this.state = {};
	}

	getItemRowTable(dataArray, cols)
	{
		if (dataArray)
		{
			const itemRowTableData = [];
			for (var x = 0, dataLen = dataArray.length; x < dataLen; x++)
			{
				var dataArr = [];
				dataArr.push(dataArray[x]);
				for (let y = 1, len = cols; y < cols; y++)
				{
					++x;
					if (x < dataLen)
						dataArr.push(dataArray[x]);
					else
						break;
				}
				itemRowTableData.push(dataArr);
			}
			return itemRowTableData;
		}
		else
		{
				return [];
		}
	}

	render()
	{
		let cols = this.props.cols;
		if (isNaN(parseInt(this.props.cols)))
			cols = 2;
		if (cols < 1)
			cols = 2;
		const itemRowTableData = this.getItemRowTable(this.props.data, cols);
		const parentUrl = this.props.parentUrl ? this.props.parentUrl : '';
		return (
			<table className="photoBookTable">
			{
				itemRowTableData &&
				itemRowTableData.length > 0 ?
				itemRowTableData.map((photogalleryRow, photogalleryRowKey) =>
					<tr>
					{photogalleryRow.map((photogallery, photogalleryKey) =>
						<td className="photo-category">
							<Link to={(photogallery.url)} state={{parentUrl: parentUrl}}>
								<img src={ photogallery.titlePhoto } alt={photogallery.title} title={photogallery.title} />
								<div dangerouslySetInnerHTML={{ __html: photogallery.title }} />
							</Link>
						</td>
					)}
					</tr>
				) : ''
			}
			</table>
		);
	}
}

export default ItemRowTable
