import React, { Component } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPhotoBooks, closeHamburger } from '../actions';
import ItemRowTable from '../containers/ItemRowTable';
import PhotoBook from "./PhotoBook";

class PhotoBooksHome extends Component {
	 constructor(props) {
			super(props);
			this.state = {
				cols: 2
			};
	 }

	componentDidMount() {
		this.props.getPhotoBooks().then(data => {
			console.log("Success");
			console.log(data);
		}).catch(err => {
			console.log("err");
			console.log(err);
		});

		if (window.innerWidth <= 900) {
			this.setState({'cols': 2});
		}
		if (window.innerWidth <= 500) {
			this.setState({'cols': 1});
		}
	}

	componentDidUpdate(prevProps, prevState)
	{

	}

 	render() {
		const photoBooks = this.props.photoBooks ? this.props.photoBooks.photoBookList : [];
		return (
						<div>
							<div className="photoTitle">Photo Books</div>
							<div className="regText">
								I've always been involved in both photograph and page layout.
								I can't explain what draws me to continue to make more photo books.
								It might simply be an artistic need which I will only understand once these books are made.
								I often collaborate with my wife Emily.
							</div>
							<div className="regText">
								Columns: <select className="regText" onChange={(e)=> this.setState({ cols: e.target.value })} value={this.state.cols}>
								<option value={1}>1</option>
								<option value={2}>2</option>
								<option value={3}>3</option>
								</select>
							</div>
							<ItemRowTable rootUrl="photobooks" cols={this.state.cols} data={photoBooks} />
						</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
		return {
			photoBooks: state.photoBookReducer.photoBooks,
			hamburger: state.hamburgerReducer.hamburgerState
		}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			getPhotoBooks: getPhotoBooks,
			closeHamburger: closeHamburger
		}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoBooksHome)
