import React, { Component } from "react";
import { bindActionCreators, compose } from 'redux';
import { withRouter } from "../lib/withRouter";
import { connect } from 'react-redux';
import { getPhotoBooks, closeHamburger } from '../actions';

class PhotoBook extends Component {
	 constructor(props) {
			super(props);
			this.state = {};
	 }

	componentDidMount() {
		this.props.getPhotoBooks().then(data => {
		}).catch(err => {
			console.log("err");
			console.log(err);
		})
	}

	componentDidUpdate(prevProps, prevState)
	{

	}

	getUrl()
	{
		return this.props.router && this.props.router.params ? this.props.router.params.url : null;
	}

 	render() {
		const url = this.getUrl();
		const hasData = this.props.photoBooks && this.props.photoBooks.photoBookDetails;
		const photoBook = hasData ? this.props.photoBooks.photoBookDetails[url] : {};
		return (
			photoBook ?
			<div>
				<table>
				<tr>
				<td className="alignTop">
						<div className="photobookContainer">
								<div className="photoTitle" dangerouslySetInnerHTML={{ __html: photoBook.title }} />
								<img src={ photoBook.titlePhoto } alt={photoBook.title} title={photoBook.title} />
								<article dangerouslySetInnerHTML={{ __html: photoBook.description }} />
								<br />
								{
									photoBook.book_url_softcover ?
									<div><a href={ photoBook.book_url_softcover } target="top">See softcover here!</a></div>
									: ''
								}
								{
									photoBook.book_url_hardcover ?
									<div><a href={ photoBook.book_url_hardcover } target="top">See hardcover here!</a></div>
									: ''
								}
						</div>
				</td>
				</tr>
				</table>
		</div>
			: <div>&nbsp;</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
		return {
			photoBooks: state.photoBookReducer.photoBooks,
			hamburger: state.hamburgerReducer.hamburgerState
		}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			getPhotoBooks: getPhotoBooks,
			closeHamburger: closeHamburger
		}, dispatch);
}

export default compose(
	withRouter, connect(mapStateToProps, mapDispatchToProps)
)(PhotoBook);
