const defaultState = {
	htmlElements: []
};

const htmlElementReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'HTML_ELEMENTS':
			return Object.assign({}, state, {
				"htmlElements": action.payload.data.htmlelements
			});

		default:
			return state;
	}
}

export default htmlElementReducer
