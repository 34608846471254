import React from 'react';
import {
	ButtonBack, ButtonFirst, ButtonLast, ButtonNext
} from 'pure-react-carousel';
import { WithStore } from 'pure-react-carousel';

class PhotoGalleryNavigation extends React.Component {
	render()
	{
		return (
			<div className={'photoNavigation'}>
				<div className={'floatLeft'}>
					<ButtonFirst className={'noButton navigationContainer'}>
						<img style={{'width': '25px'}} src={
							this.props.currentSlide === 0 ? '/siteimages/first_disabled.svg' : '/siteimages/first.svg'
 							} />
					</ButtonFirst>
					&nbsp;&nbsp;
					<ButtonBack className={'noButton navigationContainer'}>
						<img style={{'width': '13px'}} src={
							this.props.currentSlide === 0 ? '/siteimages/prev_disabled.svg' : '/siteimages/prev.svg'
 							} />
					</ButtonBack>
				</div>
				<div className={'floatRight'}>
					<ButtonNext className={'noButton navigationContainer'}>
						<img style={{'width': '13px'}} src={
							this.props.currentSlide === (this.props.totalSlides - 1) ? 'siteimages/next_disabled.svg' : 'siteimages/next.svg'
						} />
					</ButtonNext>
					&nbsp;&nbsp;
					<ButtonLast className={'noButton navigationContainer'}>
						<img style={{'width': '25px'}} src={
							this.props.currentSlide === (this.props.totalSlides - 1) ? 'siteimages/last_disabled.svg' : 'siteimages/last.svg'
						} />
					</ButtonLast>
				</div>
			</div>
		);
	}
}

export default WithStore(PhotoGalleryNavigation, state => ({
    // these are read only properties.  we use the "deepFreeze"
    // npm package to make these properties immutable. You don't have to use
    // all of these, just pick the ones you need.
    currentSlide: state.currentSlide,
    disableAnimation: state.disableAnimation,
    hasMasterSpinner: state.hasMasterSpinner,
    imageErrorCount: state.imageErrorCount,
    imageSuccessCount: state.imageSuccessCount,
    lockOnWindowScroll: state.lockOnWindowScroll,
    masterSpinnerThreshold: state.masterSpinnerThreshold,
    naturalSlideHeight: state.naturalSlideHeight,
    naturalSlideWidth: state.naturalSlideWidth,
    orientation: state.orientation,
    slideSize: state.slideSize,
    slideTraySize: state.slideTraySize,
    step: state.step,
    dragStep: state.dragStep,
    totalSlides: state.totalSlides,
    touchEnabled: state.touchEnabled,
    dragEnabled: state.dragEnabled,
    visibleSlides: state.visibleSlides,
  }));
