const defaultState = {
	photoContainers: []
};

const photoContainerReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'PHOTO_CONTAINERS':
			return Object.assign({}, state, {
				"photoContainerArray": action.payload.data
			});
		default:
			return state
	}
}

export default photoContainerReducer
