import React, { Component } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPhotoStories, getTags, closeHamburger } from '../actions';
import PhotoStory from "./PhotoStory";
import ItemRowTable from '../containers/ItemRowTable';

class PhotoStoriesHome extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cols: 3,
			tagname: ''
		};
	}

	componentDidMount()
	{
		console.log('bloooooo')
		this.props.getPhotoStories().then(data => {
			console.log("Success");
			console.log(data);
		}).catch(err => {
			console.log("err");
			console.log(err);
		});
		this.props.getTags().then(data => {
			console.log("Success");
			console.log(data);
		}).catch(err => {
			console.log("err");
			console.log(err);
		});

		if (window.innerWidth <= 900) {
			this.setState({'cols': 2});
		}
		if (window.innerWidth <= 500) {
			this.setState({'cols': 1});
		}
	}

	componentDidUpdate(prevProps, prevState)
	{
	}

	render()
	{
		const photoStories = this.props.photoStoryArray.filter(photoStory => {
			if (!photoStory.visible)
				return false;
			if (this.state.tagname === "")
				return true;
			for (let x = 0, tagLen = photoStory.tags.length; x < tagLen; x++)
			{
				if (photoStory.tags[x] === this.state.tagname)
				{
					return true;
				}
			}
			return false;
		});
		return (<div>
			<div className="photoTitle">Photo Stories</div>
			<div className="photoSubTitle">
				Retelling photographic adventures
			</div>
			<div className="regText optionsBar">
				<div>
					Tags: <select className="regText" onChange={(e)=> this.setState({ tagname: e.target.value })}>
					<option value="" selected>All tags</option>
					{this.props.photoContainerTags.map((tag, tagKey) =>
						<option value={tag}>{tag}</option>
					)}
					</select>
					&nbsp;&nbsp;
				</div>
				<div>
					Columns: <select className="regText" onChange={(e)=> this.setState({ cols: e.target.value })} value={this.state.cols}>
					<option value={1}>1</option>
					<option value={2}>2</option>
					<option value={3}>3</option>
					<option value={4}>4</option>
					</select>
				</div>
			</div>
			<ItemRowTable rootUrl="photostories" cols={this.state.cols} data={photoStories} />
		</div>);
	}
}

const mapStateToProps = (state, ownProps) => {
		return {
			photoStoryArray: state.photoStoryReducer.photoStoryArray,
			photoContainerTags: state.photoContainerTagReducer.photoContainerTags
		}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			getPhotoStories: getPhotoStories,
			getTags: getTags,
			closeHamburger: closeHamburger
		}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoStoriesHome)
