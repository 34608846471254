import React, { Component } from "react";
import { bindActionCreators, compose } from 'redux';
import { withRouter } from "../lib/withRouter";
import { getUrl } from "../lib/getUrl";
import { connect } from 'react-redux';
import Select from 'react-select';
import { isEqual, cloneDeep } from "lodash";
import {
	getPhotoGallery, getPhotoStory, closeHamburger, addStory, updateStory, updatePostPhotos, getTags, setTitlePhoto, getPhotoElements,
	updateTags, updateContent
} from '../actions';
import ImageUploader from '../containers/ImageUploader';
import { isEmpty } from 'lodash';

class EditPost extends Component {
	 constructor(props) {
			super(props);
			this.state = {
				newUrl: '',
				titlePhoto: '',
				title: '',
				subTitle: '',
				titlePhoto: '',
				date: '',
				post: '',
				order: 0,
				visible: false,
				hasGallery: false,
				skipAddData: false,
				galleryPhotos: [],
				message: '',
				error: false,
				tags: [],
				photoElements: [],
				storyTags: [],
				uploadSizeLarge: 800,
				uploadSizeMedium: 400,
				uploadSizeSmall: 62
			};

			this.fileInput = React.createRef();

			this.handleSubmit = this.handleSubmit.bind(this);
			this.handleAddPhotoElement = this.handleAddPhotoElement.bind(this);
			this.handleUpdatePhotoElement = this.handleUpdatePhotoElement.bind(this);
			this.handleAddPhotoNoLimit = this.handleAddPhotoNoLimit.bind(this);
			this.updatePhotoElementText = this.updatePhotoElementText.bind(this);
			this.updatePhotoElementPhoto = this.updatePhotoElementPhoto.bind(this);
			this.getDefaultPhotoValue = this.getDefaultPhotoValue.bind(this);
			this.deletePhotoElement = this.deletePhotoElement.bind(this);
			this.setCheckbox = this.setCheckbox.bind(this);
			this.updatePhoto = this.updatePhoto.bind(this);
			this.updateTags = this.updateTags.bind(this);
			this.updateTextFromPhotoElements = this.updateTextFromPhotoElements.bind(this);
			this.setTitlePhoto = this.setTitlePhoto.bind(this);
			this.isTagSelected = this.isTagSelected.bind(this);
			this.isPhotoSelected = this.isPhotoSelected.bind(this);
			this.handleUploadPhoto = this.handleUploadPhoto.bind(this);
			this.handleUpdatePhotos = this.handleUpdatePhotos.bind(this);
	 }

	 componentDidMount() {
 		const url = getUrl(this.props);
 		if (url)
 		{
			this.props.getPhotoStory(url, true, true, true).then(data => {
 			}).catch(err => {
 				console.log("err");
 				console.log(err);
 			});
			this.props.getTags().then(data => {}).catch(err => {
				console.log("err");
				console.log(err);
			});
			this.props.getPhotoElements().then(data => {}).catch(err => {
				console.log("err");
				console.log(err);
			});
 		}
 	}

 	componentDidUpdate(prevProps, prevState) {
 		const prevUrl = getUrl(prevProps, this.props);
 		const url = getUrl(this.props);
 		if (url && prevUrl !== url)
 		{
			this.props.getPhotoStory(url, true, true, true).then(data => {
 			}).catch(err => {
 				console.log("err");
 				console.log(err);
 			})
 		}
		if (!isEqual(prevProps, this.props))
		{
			this.setDefaultState();
		}
 	}

	setTitlePhoto(filename)
	{
		const url = getUrl(this.props);
		if (url)
		{
			this.props.setTitlePhoto(url, filename).then(data => {
			}).catch(err => {
				console.log("err");
				console.log(err);
			});
		}
	}

	setTags(e)
	{
		let value = Array.from(e.target.selectedOptions, option => option.value);
  	this.setState({tags: value});
	}

	updateTags()
	{
		const url = getUrl(this.props);
		if (url)
		{
			this.props.updateTags(url, this.state.tags).then(data => {
			}).catch(err => {
				console.log("err");
				console.log(err);
			});
		}
	}

	handleSubmit()
	{
		const url = getUrl(this.props);
		if (url === 'new')
		{
			this.handleAddStory();
		}
		else
		{
			this.handleUpdateStory();
		}
	}

	handleAddPhotoElement(selectElement)
	{
		let elementIdArr = Array.from(selectElement.target.selectedOptions, option => option.value);
		const elementId = elementIdArr ? elementIdArr[0] : null;
		let element = null;
		if (elementId)
		{
			element = this.props.photoElements.find(photoElement => photoElement.id === elementId);
			this.updatePhotoElementState(elementId, this.state.photoElements.length);
		}
	}

	updatePhotoElementState(elementId, elementIndex)
	{
		const photoElement = this.props.photoElements.find(photoElement => photoElement.id === elementId);
		const photoElements = cloneDeep(this.state.photoElements);
		if (elementIndex === photoElements.length)
		{
			photoElements.push({
				elementIndex,
				elementId: photoElement.id,
				noPhotoCountLimit: photoElement.noPhotoCountLimit,
				text: '',
				photos: Array(photoElement.numberOfPhotos).fill().map(u => ({id: ''}))
			});
		}
		else
		{
			photoElements[elementIndex].elementId = photoElement.id;
			photoElements[elementIndex].noPhotoCountLimit = photoElement.noPhotoCountLimit;
			const photoArray = [];
			const currentPhotoLen = photoElements[elementIndex].photos.length;
			for (let x = 0, photoLen = photoElement.numberOfPhotos; x < photoLen; x++)
			{
				if (x < currentPhotoLen)
				{
					photoArray.push(photoElements[elementIndex].photos[x]);
				}
				else
				{
					photoArray.push({id: ''});
				}
			}
			photoElements[elementIndex].photos = photoArray;
		}

		this.setState({ photoElements });
	}

	handleAddPhotoNoLimit(elementIndex)
	{
		const photoElements = cloneDeep(this.state.photoElements);
		console.log(photoElements);
		const photoElement = photoElements.find(photoElement => photoElement.elementIndex === elementIndex);
		console.log(photoElement);
		photoElement.photos.push({id: ''});
		this.setState({ photoElements });
	}

	updatePhotoElementText(elementIndex, text)
	{
		console.log(elementIndex)
		const photoElements = cloneDeep(this.state.photoElements);
		const photoElement = photoElements.find(photoElement => photoElement.elementIndex === elementIndex);
		photoElement.text = text;
		this.setState({ photoElements });
		console.log(photoElements);
	}

	handleUpdatePhotoElement(elementIndex, newPhotoElement)
	{
		console.log(elementIndex);
		console.log(newPhotoElement);
		this.updatePhotoElementState(newPhotoElement.value, elementIndex);
	}

	updatePhotoElementPhoto(elementIndex, photoIndex, optionObject)
	{
		console.log(elementIndex);
		console.log(photoIndex);
		console.log(optionObject);
		const photoElements = cloneDeep(this.state.photoElements);
		const photoElement = photoElements.find(photoElement => photoElement.elementIndex === elementIndex);
		console.log(cloneDeep(photoElement));
		const photoElementPhoto = photoElement.photos[photoIndex];
		photoElementPhoto.id = optionObject.value;
		console.log(cloneDeep(photoElementPhoto));
		this.setState({ photoElements });
		console.log(photoElements);
	}

	getDefaultPhotoValue(photoElementPhotoObj)
	{
		const photoElements = cloneDeep(this.state.photos);
		const photoOption = photoElements.find(photoElement => photoElement.id === photoElementPhotoObj.id);
		console.log(photoOption);
		return (
			<div className="photo-option">
				<img src={photoOption.thumb} alt={photoOption.title} />
				<span>{photoOption.title}</span>
			</div>
		);
	}

	deletePhotoElement(elementIndex)
	{
		const photoElements = cloneDeep(this.state.photoElements);
		const photoElementsAfterDelete = photoElements.filter(photoElement => photoElement.elementIndex !== elementIndex);
		this.setState({ photoElements: photoElementsAfterDelete });
	}

	updateTextFromPhotoElements()
	{
		const url = getUrl(this.props);
		if (url)
		{
			const photoElems = this.state.photoElements.map(photoElement => {
				console.log(photoElement.text);
				return {
					...photoElement,
					text: photoElement.text.replace(/"/g, '\\"')
				}
			});
			this.props.updateContent(url, photoElems).then(
				data => {}
			).catch(err => {
				console.log("err");
				console.log(err);
			});
		}
	}

	isPhotoSelected(photoElementPhotoObj, photoID, photoIndex)
	{
		const photoElements = this.state.photoElements;
		return photoElementPhotoObj.index === photoIndex && photoElementPhotoObj.id === photoID;
	}

	handleAddStory()
	{
		this.props.addStory(
			this.state.newUrl, this.state.title, this.state.subTitle, this.state.titlePhoto, this.state.date, this.state.post, this.state.visible, this.state.order, this.state.hasGallery
		).then(data => {
		}).catch(err => {
			console.log("err");
			console.log(err);
		});
	}

	handleUpdateStory()
	{
		const url = getUrl(this.props);
		this.props.updateStory(
			url, this.state.title, this.state.subTitle, this.state.titlePhoto, this.state.date, this.state.post, this.state.visible, this.state.order, this.state.hasGallery
		).then(data => {
		}).catch(err => {
			console.log("err");
			console.log(err);
		});
	}

	handleUpdatePhotos()
	{
		const url = getUrl(this.props);

		this.props.updatePostPhotos({url: url, photos: this.state.photos}).then(data => {
		}).catch(err => {
			console.log("err");
			console.log(err);
		});

	}

	handleAddPhoto()
	{
		const url = getUrl(this.props);
		this.props.updatePostPhotos(this.state.photos).then(data => {
		}).catch(err => {
			console.log("err");
			console.log(err);
		});
	}

	handleRemovePhoto()
	{
		const url = getUrl(this.props);
		this.props.updatePostPhotos(this.state.photos).then(data => {
		}).catch(err => {
			console.log("err");
			console.log(err);
		});
	}

	setDefaultState()
	{
		if (this.props)
		{
			const url = getUrl(this.props);
			const photoStoryObj = this.props.photoStoryObj[url];
			console.log(photoStoryObj);

			if (url && photoStoryObj)
			{
				const title = photoStoryObj ? photoStoryObj.title : '';
				const subTitle = photoStoryObj ? photoStoryObj.subTitle : '';
				let date_posted = photoStoryObj ? photoStoryObj.date_posted : '';
				if (date_posted)
				{
					const dateObj = new Date(date_posted)
					let month = dateObj.getMonth() + 1;
					if (month < 10)
						month = '0' + month;
					let day = dateObj.getDate();
					if (day < 10)
						day = '0' + day;
					date_posted = dateObj.getFullYear() + '-' + month + '-' + day;
				}

				const post = photoStoryObj ? photoStoryObj.post : '';
				let storyStringified = photoStoryObj ? photoStoryObj.storyJson : '';
				//storyStringified = storyStringified.replace("\"nobody I know comes back from a vacation and raves about the hotel.\"", "'nobody I know comes back from a vacation and raves about the hotel.'");
				const visible = photoStoryObj ? photoStoryObj.visible === 1 : false;
				const hasGallery = photoStoryObj ? photoStoryObj.hasGallery : false;
				const order = photoStoryObj ? photoStoryObj.order : 0;
				let photos = photoStoryObj ? photoStoryObj.photos : [];
				photos = photos.map(photo => {
					if (!photo.title)
					{
						photo.title = '';
					}
					if (!photo.description)
					{
						photo.description = '';
					}
					return photo;
				});
				const titlePhoto = photoStoryObj ? photoStoryObj.titlePhoto : '';
				let photoElements = [];
				if (storyStringified)
				{
					console.log(storyStringified.replace(/(\r\n|\n|\r)/gm, ""));
					try
					{
						photoElements = JSON.parse(storyStringified.replace(/(\r\n|\n|\r)/gm, ""));
					}
					catch(e){console.log(e);}
				}
				console.log(photoElements)

				const newState = {
					title: title,
					subTitle: subTitle,
					titlePhoto: titlePhoto,
					date: date_posted,
					post: post,
					photoElements: photoElements,
					visible: visible,
					hasGallery: hasGallery,
					photos: photos,
					order: order
				};

				if (!isEqual(newState, this.state))
				{
					this.setState(newState);
				}
			}
		}

	}

	setCheckbox(val, field)
	{
		this.setState({ [field]: !this.state[field] });
	}

	updatePhoto(id, field, value)
	{
		const photos = this.state.photos.map(photo => {
			if (photo.id === id)
			{
				photo[field] = value;
			}
			return photo;
		});
		this.setState({ photos: photos });
	}

	handleUploadPhoto()
	{
		console.log(this.fileInput.current.files[0].name);
	}

	isTagSelected(tagname)
	{
		if (!this.props.storyTags)
			return false;
		else
			return this.props.storyTags.filter(storyTag => storyTag === tagname).length > 0;
	}

 	render() {
		const url = getUrl(this.props);
		const photoStoryObj = this.props.photoStoryObj[url];
		const hasGallery = photoStoryObj ? photoStoryObj.hasGallery : false;
		const photos = photoStoryObj ? photoStoryObj.photos : [];
		const hasChildren = photoStoryObj ? photoStoryObj.hasChildren : false;
		const children = hasChildren && photoStoryObj ? photoStoryObj.children : [];
		const parentUrl = photoStoryObj ? photoStoryObj.parentUrl : '';
		const titlePhoto = photoStoryObj ? photoStoryObj.titlePhoto : '';

		const photoOptions = photos && photos.length > 0 ?
			photos.map((photoObj, photoObjKey) => {
				return { value: photoObj.id, label: photoObj.title, image: photoObj.thumb }
			}) : [];

		const photoElementOptions = this.props.photoElements && this.props.photoElements.length > 0 ?
			this.props.photoElements.sort((a,b) => a.id > b.id).map((photoElementTemplate, photoElementTemplateKey) => {
				return { value: photoElementTemplate.id, label: photoElementTemplate.id + ' - ' + photoElementTemplate.description }
			}) : [];

		console.log(photoOptions);
		console.log(photoElementOptions);
		console.log(this.state.photoElements);

		return (
			<div>
				<div className="photoTitle">{url === 'new' ? 'Add' : 'Edit'}</div>
				<table className="table-left">
					{
						this.state.error ?
						<tr><td className="regText error" colSpan={2}>Auth error</td></tr> : ''
					}
					{
						this.state.message ?
						<tr><td className="regText green-message" colSpan={2}>{this.state.message}</td></tr> : ''
					}

					{
						url === 'new' ?
						<tr>
							<td className="regText">New URL:</td>
							<td><input type="text" className="regText" name="newUrl" size={50} onChange={(e)=> this.setState({ newUrl: e.target.value })} /></td>
						</tr>
						: ''
					}
					<tr><td className="regText" colspan={2}>
						{
						url === 'new' || !titlePhoto ?
						<span>No title photo</span>
						:
						<img src={titlePhoto} />
						}
					</td>
					</tr>

					<tr>
						<td className="regText">Title:</td>
						<td><input type="text" className="regText" name="title" defaultValue={this.state.title} size={50} onChange={(e)=> this.setState({ title: e.target.value })} /></td>
					</tr>

					<tr>
						<td className="regText">Subtitle:</td>
						<td><input type="text" className="regText" name="subTitle" defaultValue={this.state.subTitle} size={50} onChange={(e)=> this.setState({ subTitle: e.target.value })} /></td>
					</tr>

					<tr>
						<td className="regText">Date:</td>
						<td><input type="text" name="date" className="regText" defaultValue={this.state.date} size={50} onChange={(e)=> this.setState({ date: e.target.value })} /></td>
					</tr>

					<tr>
						<td className="regText">Visible:</td>
						<td><input type="checkbox" checked={this.state.visible} value="true" onChange={(e)=> this.setCheckbox(e.target.value, 'visible')} /></td>
					</tr>

					<tr>
						<td className="regText">Order:</td>
						<td><input type="number" name="order" className="regText" value={this.state.order} size={4} onChange={(e)=> this.setState({ order: e.target.value })} /></td>
					</tr>

					<tr>
						<td className="regText">Story:</td>
						<td><textarea cols={80} rows={8} name="story" defaultValue={this.state.post} onChange={(e)=> this.setState({ post: e.target.value })} /></td>
					</tr>
					<tr>
						<td className="regText">Story Objects:</td>
						<td>
							<div>
								<div class="photo-element-toolbar-title">Toolbar</div>
								<div class="photo-element-toolbar-container">
									<select className="regText" name="stuff" onChange={(e)=> this.handleAddPhotoElement(e)}>
										<option>This is a list</option>
										{this.props.photoElements.sort((a,b) => a.id > b.id).map((photoElement, photoElementKey) =>
										<option value={photoElement.id}>{photoElement.id} - {photoElement.description}</option>
										)}
									</select>
									<br />
								</div>
								<div class="photo-element-container">
									<div class="photo-element-container-row">
										<div>ID</div><div>Photos</div><div>Text</div><div>Del</div>
									</div>
								{this.state.photoElements.map((photoElement, photoElementKey) =>
									<div class="photo-element-container-row">
										<div>
											<Select
												onChange={(e)=> this.handleUpdatePhotoElement(photoElement.elementIndex, e)}
												defaultValue={photoElementOptions.find(photoTemplate => photoTemplate.value === photoElement.elementId)}
												options={photoElementOptions}
											/>
										</div>
										<div>
											{photoElement.photos.map((photoElementPhotoObj, photoIndex) =>
												<div>
													<Select
														onChange={(e)=> this.updatePhotoElementPhoto(photoElement.elementIndex, photoIndex, e )}
														defaultValue={photoOptions.find(photoOption => photoOption.value === photoElementPhotoObj.id)}
														options={photoOptions}
														formatOptionLabel={photoOption => (
														<div className="photo-option">
															<img src={photoOption.image} alt={photoOption.label} />
															<span>{photoOption.label}</span>
														</div>
														)}
													/>
												</div>
											)}
											{photoElement.noPhotoCountLimit ? <button type="button" onClick={(e)=> this.handleAddPhotoNoLimit(photoElement.elementIndex)}>Add photo</button> : ''}
										</div>
										<div>
											<textarea cols={20} rows={8} name="story" defaultValue={photoElement.text} onChange={(e)=> this.updatePhotoElementText(photoElement.elementIndex, e.target.value )} />
										</div><div class="photo-element-delete" onClick={(e) => this.deletePhotoElement(photoElement.elementIndex)}>x</div>
									</div>
								)}
								</div>
							</div>
							<button type="button" onClick={this.updateTextFromPhotoElements}>Update text from photo elements</button>
						</td>
					</tr>

					<tr>
						<td className="regText">Tags:</td>
						<td><select className="regText" name="tags" onChange={(e)=> this.setTags(e)} multiple>
								{this.props.tags.map((tag, tagKey) =>
								<option value={tag} selected={this.isTagSelected(tag)}>{tag}</option>
								)}
							</select>
							<button type="button" onClick={this.updateTags}>Update tags</button>
						</td>
					</tr>

					<tr>
						<td className="regText" colspan={2}>
							<ImageUploader uploadDir={url} sizeSmall={this.state.uploadSizeSmall} sizeMedium={this.state.uploadSizeMedium} sizeLarge={this.state.uploadSizeLarge} skipAddData={this.state.skipAddData} />
							<input type="text" name="uploadSizeLarge" onChange={(e)=> this.setState({ uploadSizeLarge: e.target.value })} defaultValue={this.state.uploadSizeLarge} class="upload-size" />
							<input type="text" name="uploadSizeMedium" onChange={(e)=> this.setState({ uploadSizeMedium: e.target.value })} defaultValue={this.state.uploadSizeMedium} class="upload-size" />
							<input type="text" name="uploadSizeSmall" onChange={(e)=> this.setState({ uploadSizeSmall: e.target.value })} defaultValue={this.state.uploadSizeSmall} class="upload-size" />
							<input name="skipAddData" type="checkbox" checked={this.state.skipAddData} value="true" onChange={(e)=> this.setCheckbox(e.target.value, 'skipAddData')} />
							<span>&nbsp;Only photo upload; skip adding data for this photo</span>
						</td>
					</tr>

					<tr>
						<td className="regText">Has gallery:</td>
						<td><input type="checkbox" checked={this.state.hasGallery} value="true" onChange={(e)=> this.setCheckbox(e.target.value, 'hasGallery')} /></td>
					</tr>
					<tr><td className="regText">Gallery photos:</td><td><table>
					{
						photos && photos.length > 0 ?
						photos.map((photoObj, photoKey) => {
							return (
								<tbody>
								<tr><td colspan="2">
									<a href={photoObj.image} target="top"><img src={photoObj.thumb} /></a>
									<input type="hidden" value={photoObj.id} />
									&nbsp;
									<button type="button" onClick={(e)=> this.setTitlePhoto(photoObj.rootimage)}>Set as title</button>
								</td></tr>
								<tr><td className="regTextBold">Title</td><td className="regTextBold">
									<input type="text" className="row-input" defaultValue={photoObj.title} onChange={(e)=> this.updatePhoto(photoObj.id, 'title', e.target.value, 'hasGallery')} />
								</td></tr>
								<tr><td className="regText vertical-align-top">Description</td><td className="regText">
									<textarea rows={2} cols={80} onChange={(e)=> this.updatePhoto(photoObj.id, 'description', e.target.value, 'hasGallery')}>{photoObj.description}</textarea>
								</td></tr>
								<tr><td className="regText">Image</td><td className="regText">
									<input type="text" className="row-input" defaultValue={photoObj.rootimage} onChange={(e)=> this.updatePhoto(photoObj.id, 'rootimage', e.target.value, 'hasGallery')} />
								</td></tr>
								<tr><td className="regText">Image size</td><td className="regText">
									<input type="text" size={4} defaultValue={photoObj.width_large} onChange={(e)=> this.updatePhoto(photoObj.id, 'width_large', e.target.value, 'hasGallery')} />
									x
									<input type="text" size={4} defaultValue={photoObj.height_large} onChange={(e)=> this.updatePhoto(photoObj.id, 'height_large', e.target.value, 'hasGallery')} />
								</td></tr>
								<tr><td className="regText">Thumbnail size</td><td className="regText">
									<input type="text" size={4} defaultValue={photoObj.width_small} onChange={(e)=> this.updatePhoto(photoObj.id, 'width_small', e.target.value, 'hasGallery')} />
									x
									<input type="text" size={4} defaultValue={photoObj.height_small} onChange={(e)=> this.updatePhoto(photoObj.id, 'height_small', e.target.value, 'hasGallery')} />
								</td></tr>
								<tr><td className="regText">Order</td><td className="regText">
									<input type="text" size={4} defaultValue={photoObj.order} onChange={(e)=> this.updatePhoto(photoObj.id, 'order', e.target.value, 'hasGallery')} />
								</td></tr>
								<tr><td className="regText">Hidden</td><td className="regText">
									<input type="checkbox" checked={photoObj.hidden} value="1" onChange={(e)=> this.updatePhoto(photoObj.id, 'hidden', e.target.value, 'hasGallery')} />
								</td></tr>
								</tbody>
							)
						})
						: ''
					}
					{
						photos && photos.length > 0 ?
						<tbody>
							<tr><td colspan="2">
								<button type="button" onClick={this.handleUpdatePhotos}>Update photos</button>
							</td></tr>
						</tbody>
						: ''
					}
					</table></td></tr>
					<tr>
						<td className="regText" colSpan={2}>
							<button type="button" class="edit-post-submit-button" onClick={this.handleSubmit}>Submit</button>
							<br />
						</td>
					</tr>
				</table>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const url = getUrl(ownProps);
  let storyTags = null;
	if (url && !isEmpty(state.photoStoryReducer.photoStory))
	{
			storyTags = state.photoStoryReducer.photoStory[url].tags ? state.photoStoryReducer.photoStory[url].tags.split(',') : []
	}

	return {
		photoStoryObj: state.photoStoryReducer.photoStory,
		tags: state.photoContainerTagReducer.photoContainerTags,
		photoElements: state.photoElementReducer.photoElements,
		url,
		storyTags
	}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			getPhotoGallery: getPhotoGallery,
			getPhotoStory: getPhotoStory,
			closeHamburger: closeHamburger,
			setTitlePhoto: setTitlePhoto,
			updateStory: updateStory,
			getTags: getTags,
			getPhotoElements: getPhotoElements,
			updateTags: updateTags,
			updateContent: updateContent,
			addStory: addStory,
			updatePostPhotos: updatePostPhotos
		}, dispatch);

}

export default compose(
	withRouter, connect(mapStateToProps, mapDispatchToProps)
)(EditPost);
