import React, { useState } from "react";
import { Line } from "rc-progress";
import Uploady, { useItemProgressListener } from "@rpldy/uploady";
import UploadButton from "@rpldy/upload-button";

const UploadProgress = () => {
  const [progress, setProgess] = useState(0);
  const progressData = useItemProgressListener();

  if (progressData && progressData.completed > progress) {
    setProgess(() => progressData.completed);
  }

  return (
    progressData && (
      <Line
        style={{ height: "10px", marginTop: "20px" }}
        strokeWidth={2}
        strokeColor={progress === 100 ? "#00a626" : "#2db7f5"}
        percent={progress}
      />
    )
  );
};

/*
sizeSmall={this.state.uploadSizeSmall} sizeMedium={this.state.uploadSizeMedium} sizeLarge
*/
export default function ImageUploader({uploadDir, sizeSmall, sizeMedium, sizeLarge, skipAddData}) {
	console.log(uploadDir);
  return (
    <Uploady
      destination={{ url: "https://zionorent.com/api/imageupload" }}
			params={{posturl: uploadDir, sizeSmall, sizeMedium, sizeLarge, skipAddData}}
			withCredentials={true}
			method="POST"
			sendWithFormData={true}
			inputFieldName="photos"
    >
      <div className="App">
        <UploadButton />
        <br />
        <UploadProgress />
      </div>
    </Uploady>
  );
}
