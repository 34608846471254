const hamburgerReducer = (state = {"hamburgerState": false}, action) => {
	switch (action.type) {
		case 'HAMBURGER_OPEN':
			return Object.assign({}, state, {"hamburgerState": true})
		case 'HAMBURGER_CLOSE':
			return Object.assign({}, state, {"hamburgerState": false})
		case 'HAMBURGER_TOGGLE':
			return Object.assign({}, state, {"hamburgerState": !state.hamburgerState})
		default:
			return state
	}
}

export default hamburgerReducer
