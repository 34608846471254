import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';
import rootReducer from './reducers';
import App from "./components/App";
import { HashRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const store = createStore(
	rootReducer,
	applyMiddleware(thunk, logger)
);
render(
	<Provider store={store}>
		<HashRouter>
			<DndProvider backend={HTML5Backend}>
				<App />
			</DndProvider>
		</HashRouter>
	</Provider>,
	document.getElementById('root')
)
