const defaultState = {
	photoContainerTags: []
};

const photoContainerTagReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'PHOTO_CONTAINER_TAGS':
			return Object.assign({}, state, {
				"photoContainerTags": action.payload.data
			});

		default:
			return state
	}
}

export default photoContainerTagReducer
