import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DragElem } from '../containers/DragElem';
import { DropZone } from '../containers/DropZone';
import { getAlbumCategories, getHtmlElements, closeHamburger } from '../actions';

class Admin extends Component {
	 constructor(props) {
			super(props);

			this.state = {};
	 }

	componentDidMount()
	{
 		this.props.getAlbumCategories().then(data => {}).catch(
			err => {
 				console.log("err");
 				console.log(err);
 			}
		);
		this.props.getHtmlElements().then(data => {}).catch(
			err => {
 				console.log("err");
 				console.log(err);
 			}
		);
 	}

	onDropped(item)
	{
		this.props.htmlElements.map((htmlElement, htmlElementKey) => {
			if (htmlElement.name === item.name)
			{
				console.log(item.name + ' moooo');
			}
		});
	}

	render()
	{
		const htmlElements = this.props.htmlElements;

		return (
			<div>
				<div className="admin-title">Admin page</div>
				<div className="admin-page-container">
					<div className="html-element-container">
						{htmlElements.map((htmlElement, htmlElementKey) =>
							<div><DragElem name={htmlElement.name} onDropped={(item) => this.onDropped(item)} /></div>
						)}
					</div>
					<DropZone />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		albumCategories: state.albumCategoryReducer.albumCategories,
		htmlElements: state.htmlElementReducer.htmlElements
	}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		getAlbumCategories: getAlbumCategories,
		getHtmlElements: getHtmlElements,
		closeHamburger: closeHamburger
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
