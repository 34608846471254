import React, { Component } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { getPhotoGalleries, closeHamburger } from '../actions';
import PigsInSpace from "./PigsInSpace";
import ItemRowTable from '../containers/ItemRowTable';
import PhotoGallery from "./PhotoGallery";

class PhotoGalleriesHome extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cols: 3
		};
	}

 componentDidMount() {
	 this.props.getPhotoGalleries().then(data => {
		 console.log("Success");
		 console.log(data);
	 }).catch(err => {
		 console.log("err");
		 console.log(err);
	 });

		if (window.innerWidth <= 900) {
			this.setState({'cols': 2});
		}
		if (window.innerWidth <= 500) {
			this.setState({'cols': 1});
		}
 }

 componentDidUpdate(prevProps, prevState)
	{
	 ;
	}

	render() {
		console.log('rend')
		return (
					<div>
						<div className="photoTitle">Photo Galleries</div>
						<div className="regText">
							From Brooke Shaden's book <a href="https://play.google.com/store/books/details?pcampaignid=books_read_action&id=vlF7AgAAQBAJ" className="reg" target="top">Inspiration in Photography</a> on creating series of images:
						</div>
						<div className="regText">
							<i>Creating a series is relevant because it allows the photographer to tell a meaningful story through images in an organic way.... [A] series takes the viewer into a new world. Suddenly each picture expands on the last and when images relate, the story grows.</i>
						</div>
						<div className="regText">
							Columns: <select className="regText" onChange={(e)=> this.setState({ cols: e.target.value })} value={this.state.cols}>
							<option value={1}>1</option>
							<option value={2}>2</option>
							<option value={3}>3</option>
							<option value={4}>4</option>
							</select>
						</div>
						<ItemRowTable rootUrl="photogalleries" cols={this.state.cols} data={this.props.photoGalleryArray} />
					</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
		return {
			photoGalleryArray: state.photoGalleryReducer.photoGalleryArray
		}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			getPhotoGalleries: getPhotoGalleries,
			closeHamburger: closeHamburger
		}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoGalleriesHome);
