import React, { Component } from "react";
import { Navigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPhotoGalleries, closeHamburger } from '../actions';

class Login extends Component {
	 constructor(props) {
			super(props);
			this.state = {
				username: '',
				password: '',
				error: false,
				redirect: false
			};
			this.handleSubmit = this.handleSubmit.bind(this);
	 }

	componentDidMount()
	{
	}

	handleSubmit()
	{
		const formBody = 'username=' + encodeURIComponent(this.state.username) + '&password=' + encodeURIComponent(this.state.password);
/*
headers: {'Content-Type': 'application/json'},
body: JSON.stringify({
	username: encodeURIComponent(this.state.username),
	password: encodeURIComponent(this.state.password)
})
*/
		const headers = new Headers();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers.append('Accept', 'application/json');

		const response = fetch("/api/auth", {
			method: "POST",
			headers: headers,
			credentials: 'same-origin',
			mode: 'same-origin',
			body: formBody
		})
		.then(response => response.json())
	  .then(data => {
			console.log(data);
			if (data !== true)
			{
				this.setState({ error: true });
			}
			else
			{
				this.setState({ error: false, redirect: true });
			}

		}).catch(error => {
			console.log(error)
		});
	}

	renderRedirect = () => {
		if (this.state.redirect) {
			return <Navigate to='/chooseeditpost' />
		}
	}

 	render() {
		return (
			<div>
				<div className="photoTitle">Login</div>

				{
					this.state.error ?
					<p className="regText error">Login error</p> : ''
				}
				{this.renderRedirect()}

					<p className="regText">
						Username: <input type="text" name="username" onChange={(e)=> this.setState({ username: e.target.value })} />
					</p>

					<p className="regText">
						Password: <input type="password" name="password" onChange={(e)=> this.setState({ password: e.target.value })} />
					</p>
					<button type="button" onClick={this.handleSubmit}>Login</button>
					<br />
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
		return {}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			closeHamburger: closeHamburger
		}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
