import React, { Component } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { getPhotoGalleries, closeHamburger } from '../actions';
import PigsInSpace from "./PigsInSpace";
import ItemRowTable from '../containers/ItemRowTable';
import PhotoGalleriesHome from "./PhotoGalleriesHome";
import PhotoGallery from "./PhotoGallery";

class PhotoGalleries extends Component {
	constructor(props) {
		super(props);
	}

 componentDidMount() {
 }

 componentDidUpdate(prevProps, prevState)
	{
	 ;
	}

 	render() {
		console.log('bloo');
		console.log(this.props);
		return (
			<Routes>
				<Route path="" element={<PhotoGalleriesHome />} />
				<Route path=":id" element={<PhotoGallery />} />
			</Routes>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
		return {
			photoGalleryArray: state.photoGalleryReducer.photoGalleryArray
		}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			getPhotoGalleries: getPhotoGalleries,
			closeHamburger: closeHamburger
		}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoGalleries);
