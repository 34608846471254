/* Import statements */
import React, { Component } from "react";
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { withRouter } from "../lib/withRouter";
import { closeHamburger, toggleHamburger, getPhotoBooks } from '../actions';
import Home from "./Home";
import PigsInSpace from "./PigsInSpace";
import About from "./About";
import Admin from "./Admin";
import Login from "./Login";
import ChooseEditPost from "./ChooseEditPost";
import EditPost from "./EditPost";
import PhotoGalleries from "./PhotoGalleries";
import PhotoGallery from "./PhotoGallery";
import PhotoStories from "./PhotoStories";
import PhotoStory from "./PhotoStory";
import PhotoBooks from "./PhotoBooks";
import PhotoBook from "./PhotoBook";
import Footer from "../containers/Footer";
import "../css/styles.scss";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showSmallViewMenu: false
		};
	}

	componentDidMount()
	{
		this.props.getPhotoBooks().then(data => {
			console.log("Success");
			console.log(data);
		}).catch(err => {
			console.log("err");
			console.log(err);
		});
	}

	componentDidUpdate(prevProps, prevState)
	{
		if (this.props.hamburgerState !== prevProps.hamburgerState)
		{
			this.setState({'showSmallViewMenu': this.props.hamburgerState});
		}
	}

	getIsInCategory(linkName)
	{
		return this.props.router.location.pathname.indexOf('/' + linkName) !== -1;
	}

	getClassName(linkName)
	{
		return this.props.router.location.pathname === ('/' + linkName) ? "mainCategoryCurrent" : "";
	}
/*
	getRoutePath(location: Location, params: Params) {
	  const { pathname } = location;

	  if (!Object.keys(params).length) {
	    return pathname; // we don't need to replace anything
	  }

	  let path = pathname;
	  Object.entries(params).forEach(([paramName, paramValue]) => {
	    if (paramValue) {
	      path = path.replace(paramValue, `:${paramName}`);
	    }
	  });
	  return path;
	};
*/
 	render() {
		//const location = useLocation();
		//const params = useParams();
		//const path = getRoutePath(location, params);
		const isHome = this.props.router.location.pathname === '/';
		const isAdmin = this.props.router.location.pathname === '/admin';
		const isLogin = this.props.router.location.pathname === '/login';
		const currentYear = new Date().getFullYear();

		return (
			<span>
			{ isHome ?
			<div className="homePageContainer">
				<Routes>
					<Route path="/" element={<Home />} />
				</Routes>
				<Footer isHomePage={true} />
			</div>
			: ''}
			{ isAdmin ?
			<div>
				<Routes>
					<Route path="/" element={<Admin />} />
				</Routes>
			</div>
			: ''}
			{ (!isHome && !isAdmin) ?
			<table>
			<tr><td className="alignTop">
				<div className="hamburger">
					<img className="hamburgerMenu" onClick={this.props.toggleHamburger} src="siteimages/hamburger.png" />
				</div>
				<div className={(this.state.showSmallViewMenu ? 'hamburgerlinks' : 'links')}>
					<nav className="navbar navbar-light">
						<ul className="nav navbar-nav">
							<li>
								<div className="closeHamburgerNavigation" onClick={this.props.closeHamburger}>x</div>
								<div className={this.getClassName('photogalleries')}>
									<Link to="/photogalleries" onClick={this.props.closeHamburger}>Photo galleries</Link>
								</div>
							</li>
							<li>
								<div className={this.getClassName('photostories')}>
									<Link to="/photostories" onClick={this.props.closeHamburger}>Photo stories</Link>
								</div>
							</li>
							<li>
								<div className={this.getClassName('photobooks')}>
									<Link to="/photobooks" onClick={this.props.closeHamburger}>Photo books</Link>
								</div>
							</li>
							{
								this.props.photoBooks && this.getIsInCategory('photobooks') ?
								Object.entries(this.props.photoBooks.photoBookDetails).map(([key,photobook]) =>
									<li>
										<div className={this.getClassName('photobooks/'+ photobook.url) + ' sublinks'}>
											<Link to={'/photobooks/' + photobook.url} onClick={this.props.closeHamburger}
												dangerouslySetInnerHTML={{ __html: photobook.title }} />
										</div>
									</li>
								)
								: ''
							}
							<li>
								<div className={this.getClassName('about')}>
									<Link to="/about" onClick={this.props.closeHamburger}>About</Link>
								</div>
							</li>
							<li>
								<div className={this.getClassName('home')}>
									<Link to="/" onClick={this.props.closeHamburger}>Home</Link>
								</div>
							</li>
							<li>
								<div>
									<a href="https://www.instagram.com/friendofbearsandmoose/" target="top">
											<img src="siteimages/ig.svg" style={{'width': '28px'}} />
									</a>
								</div>
							</li>
						</ul>
					</nav>
				</div>
			</td>
			<td className="alignTop mainBody">
				<Routes>
					<Route path="photogalleries" element={<PhotoGalleries />}>
						<Route path=":id" element={<PhotoGallery />} />
					</Route>
					<Route path="photostories" element={<PhotoStories />}>
						<Route path=":url" element={<PhotoStory />}>
							<Route path=":url" element={<PhotoStory />} />
						</Route>
					</Route>
					<Route path="photobooks" element={<PhotoBooks />}>
						<Route path=":url" element={<PhotoBook />} />
					</Route>
					<Route path="about" element={<About />} />
					<Route path="login" element={<Login />} />
					<Route path="chooseeditpost" element={<ChooseEditPost />}>
						<Route path=":url" element={<EditPost />} />
					</Route>
					<Route path='*' element={<PigsInSpace />} />
				</Routes>
				<Footer isHomePage={false} />
			</td></tr>
			</table>
			: ''}
			</span>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
		return {
			photoBooks: state.photoBookReducer.photoBooks,
			hamburgerState: state.hamburgerReducer.hamburgerState
		}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			getPhotoBooks: getPhotoBooks,
			closeHamburger: closeHamburger,
			toggleHamburger: toggleHamburger
		}, dispatch);
}

export default compose(
	withRouter, connect(mapStateToProps, mapDispatchToProps)
)(App);
