import { combineReducers } from 'redux';
import authorReducer from './authorReducer';
import hamburgerReducer from './hamburgerReducer';
import homePageReducer from './homePageReducer';
import photoBookReducer from './photoBookReducer';
import photoContainerReducer from './photoContainerReducer';
import photoElementReducer from './photoElements';
import photoGalleryReducer from './photoGalleryReducer';
import photoStoryReducer from './photoStoryReducer';
import photoContainerTagReducer from './tags';
import albumCategoryReducer from './albumCategoryReducer';
import htmlElementReducer from './htmlElementReducer';

export default combineReducers({
	authorReducer,
	hamburgerReducer,
	homePageReducer,
	photoBookReducer,
	photoContainerReducer,
	photoElementReducer,
	photoGalleryReducer,
	photoStoryReducer,
	photoContainerTagReducer,
	htmlElementReducer,
	albumCategoryReducer
})
