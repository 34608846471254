import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../lib/withRouter";
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { getPhotoStory, getPhotoStories, getAuthors } from '../actions';
import PigsInSpace from "./PigsInSpace";
import PhotoGalleryContainer from '../containers/PhotoGalleryContainer';
import ItemRowTable from '../containers/ItemRowTable';

class PhotoStory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			imageUrl: '',
			imageCaption: ''
		};

		this.closeLightbox = this.closeLightbox.bind(this);
	}

	componentDidMount() {
		const url = this.getUrl();
		console.log(url)
		if (url)
		{
			this.props.getPhotoStory(url, false, false, false).then(data => {
				console.log("Success");
				console.log(data);
			}).catch(err => {
				console.log("err");
				console.log(err);
			});
			this.props.getAuthors().then(data => {
				console.log("Success");
				console.log(data);
			}).catch(err => {
				console.log("err");
				console.log(err);
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		console.log('componentDidUpdateYeah')
		const prevUrl = this.getUrl(prevProps);
		const url = this.getUrl();
		if (url && prevUrl !== url)
		{
			this.props.getPhotoStory(url, false, false, false).then(data => {
				console.log("Success");
				console.log(data);
			}).catch(err => {
				console.log("err");
				console.log(err);
			})
		}
	}

	expandImageOnclick(e) {
			if (e && e.target && e.target.dataset && 'largeUrl' in e.target.dataset)
			{
				const imageCaption = 'caption' in e.target.dataset ? e.target.dataset.caption : '';
				this.setState({
					'open': true,
					'imageUrl': e.target.dataset.largeUrl,
					'imageCaption': imageCaption
				});
			}
	}

	closeLightbox() {
		this.setState({'open': false});
	}

	getUrl(props)
	{
		const propToUse = props ? props : this.props;
		return propToUse.router && propToUse.router.params ? propToUse.router.params.url : null;
	}

 	render() {
		console.log(this.props);
		const url = this.getUrl();
		console.log(url)
		const photoStoryObj = this.props.photoStoryObj[url];
		const title = photoStoryObj ? photoStoryObj.title : url;
		const subTitle = photoStoryObj ? photoStoryObj.subTitle : '';
		let date_posted = photoStoryObj ? photoStoryObj.date_posted : '';
		if (date_posted)
		{
			const dateObj = new Date(date_posted)
			let month = dateObj.getMonth() + 1;
			if (month < 10)
				month = '0' + month;
			let day = dateObj.getDate();
			if (day < 10)
				day = '0' + day;
			date_posted = dateObj.getFullYear() + '-' + month + '-' + day;
		}
		const post = photoStoryObj ? photoStoryObj.post : '';
		const hasGallery = photoStoryObj ? photoStoryObj.hasGallery : false;
		const photos = hasGallery && photoStoryObj ? photoStoryObj.photos : [];
		const parentUrl = photoStoryObj ? photoStoryObj.parentUrl : '';
		const hasParent = !!parentUrl;
		const hasChildren = photoStoryObj ? photoStoryObj.hasChildren : false;
		let children = hasChildren && photoStoryObj ? photoStoryObj.children : [];
		let cameFromParent = !!this.props.parentUrl;
		console.log(hasChildren);
		console.log(children);
		if (hasChildren)
		{
			children = children.map(child => {
				if (child.photoCategory	!== "photostories")
				{
					child.url = "/" + child.photoCategory + "/" + child.url;
				}
				return child;
			});
		}

		const hasNoTitle = photoStoryObj && photoStoryObj.title === null;
		const hasNoPost = photoStoryObj && photoStoryObj.post === '';
		const hasNoDate = photoStoryObj && photoStoryObj.date_posted === null;
		const notFound = photoStoryObj && hasNoTitle && hasNoDate && hasNoPost;

		console.log(photoStoryObj)
		console.log(this.props.authors);
//photoStoryObj.authorId
		const author = photoStoryObj ? this.props.authors.filter(author => author.id === photoStoryObj.authorId)[0] : [];
		const hasAuthor = !!photoStoryObj;
		console.log(author);
		console.log(hasAuthor);

		return (
				url ?
					<span>
					{notFound ?
					<PigsInSpace /> :
					<div class='blogpost'>
							<div className="photoTitle" dangerouslySetInnerHTML={{ __html: title }} />
			        <div className="photoSubTitle">{subTitle}</div>
							{
								date_posted ?
								<div className="photoDate">{date_posted}</div>
								: ''
							}
							{
								hasAuthor && author && !author.isOwner ?
								<div className="photo-author">By {author.first_name} {author.last_name}</div>
								: ''
							}
							{
								hasParent ?
								<div className="alignLeft" style={{color: 'blue'}}>
									<Link to=".." relative="path">
										<a className="blue-link">
											Back to parent
										</a>
									</Link>
								</div>
								: ''
							}
							{
								hasGallery ?
								<PhotoGalleryContainer photos={photos}>
								</PhotoGalleryContainer>
								: ''
							}
			        <article className="regText alignLeft" onClick={(e) => this.expandImageOnclick(e)} dangerouslySetInnerHTML={{ __html: post }} />
							{
								hasChildren && children.length > 0 ?
								<ItemRowTable rootUrl="photostories" parentUrl={'/photostories/' + url} cols={2} data={children} />
									: ''
							}
							{
								this.state.open ? (
										<Lightbox
											image={this.state.imageUrl}
											title={this.state.imageCaption}
											onClose={this.closeLightbox} />
								  ) : ''
							}
			    </div>
				}
				</span> : ''
		);
	}
}

const mapStateToProps = (state, ownProps) => {
  const parentUrl = ownProps && ownProps.location && ownProps.location.state && ownProps.location.state.parentUrl ? ownProps.location.state.parentUrl : '';
	return {
		authors: state.authorReducer.authors,
		photoStoryObj: state.photoStoryReducer.photoStory,
		parentUrl: parentUrl
	}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		  getAuthors: getAuthors,
			getPhotoStory: getPhotoStory,
			getPhotoStories: getPhotoStories
		}, dispatch);
}

export default compose(
	withRouter, connect(mapStateToProps, mapDispatchToProps)
)(PhotoStory);
