import React, { Component } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPhotoStories, getTags, closeHamburger } from '../actions';
import PhotoStoriesHome from "./PhotoStoriesHome";
import PhotoStory from "./PhotoStory";
import ItemRowTable from '../containers/ItemRowTable';

class PhotoStories extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount()
	{
	}

	componentDidUpdate(prevProps, prevState)
	{
	}

	render()
	{
		console.log('jkkajsn')
		console.log(this.props)
		return (
			<Routes>
				<Route exact path="" element={<PhotoStoriesHome />} />
				<Route exact path=":url" element={<PhotoStory />} />
				<Route exact path=":urlprefix/:url" element={<PhotoStory />} />
			</Routes>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
		return {
			photoStoryArray: state.photoStoryReducer.photoStoryArray,
			photoContainerTags: state.photoContainerTagReducer.photoContainerTags
		}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			getPhotoStories: getPhotoStories,
			getTags: getTags,
			closeHamburger: closeHamburger
		}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoStories)
