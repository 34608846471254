import React, { Component, useEffect, useState  } from 'react';
import PhotoGalleryDescription from './PhotoGalleryDescription';
import PhotoGalleryNavigation from './PhotoGalleryNavigation';
import {
	CarouselProvider,
	ButtonBack, ButtonFirst, ButtonLast, ButtonNext,
	Image, Slide, Slider, Dot
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

class PhotoGalleryContainer extends Component
{
	constructor(props) {
		 super(props);
		 this.state = {};
	}

	getPhotoClassName(photoGalleryPhoto)
	{
		const photoWidth = parseInt(photoGalleryPhoto.width_large);
		const photoHeight = parseInt(photoGalleryPhoto.height_large);
		if (!isNaN(photoWidth) && !isNaN(photoHeight))
		{
			if (photoWidth > photoHeight)
			{
				let photoClassName = 'photo_horizontal_medium';
				if (photoHeight < 534)
				{
					photoClassName += ' photo_horizontal_medium_irregheight';
				}
				return photoClassName;
			}
			else
			{
				return 'photo_vertical_medium';
			}
		}
		else
		{
			return 'photo_horizontal_medium';
		}
	}

	getExtraStyles(photoGalleryPhoto)
	{
		const photoWidth = parseInt(photoGalleryPhoto.width_large);
		const photoHeight = parseInt(photoGalleryPhoto.height_large);
		if (!isNaN(photoWidth) && !isNaN(photoHeight))
		{
			if (photoWidth > photoHeight && photoHeight < 534)
			{
				let denominator;
				// For unknown reasons the iPhone needs this to be divided by 4
				if (window.innerWidth <= 500)
				{
					denominator = 4;
				}
				else
				{
					denominator = 2;
				}
				const marginTop = Math.floor((534 - photoHeight) / denominator);
				return Object.assign({},
				{
					'margin-top': (marginTop) + 'px',
					'margin-bottom': (marginTop * -1) + 'px'
				}
				);
			}
			else
			{
				return Object.assign({});
			}
		}
		else
		{
			return Object.assign({});
		}
	}

	render ()
	{
		return (
			<CarouselProvider
				visibleSlides={1}
				totalSlides={this.props.photos.length}
				step={1}
				naturalSlideWidth={800}
				naturalSlideHeight={534}
				hasMasterSpinner={true}
			>
				<Slider className="photoLargeSlider">
				{this.props.photos.map((galleryItem, galleryItemKey) =>
					<Slide index={galleryItemKey} className={this.getPhotoClassName(galleryItem)} style={this.getExtraStyles(galleryItem)}>
						<Image src={galleryItem.image} />
					</Slide>
				)
				}
				</Slider>
				<PhotoGalleryNavigation />
				<div className="photoThumbnailContainer">
				{this.props.photos.map((galleryItem, galleryItemKey) =>
					<Dot slide={galleryItemKey} className={'photo_thumbnail'}>
						<img src={galleryItem.thumb} alt={galleryItem.title} title={galleryItem.title} />
					</Dot>
				)
				}
				</div>
				<PhotoGalleryDescription photos={this.props.photos} />
			</CarouselProvider>
		);
	}
}

export default PhotoGalleryContainer
