import axios from "axios";

const headers = {
	'Access-Control-Allow-Origin': '*',
	'Cache-Control': 'max-age=604800'
};

export function getAlbumCategories(url)
{
	return function (dispatch) {
		return axios.get(`/api/data/albumcategories.php`, {
			headers: headers
		})
		.then(function (response) {
			// handle success
			dispatch({type: "ALBUM_CATEGORIES", url: url, payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}

export function getHtmlElements(url)
{
	return function (dispatch) {
		return axios.get(`/api/data/htmlElements.php`, {
			headers: headers
		})
		.then(function (response) {
			// handle success
			dispatch({type: "HTML_ELEMENTS", url: url, payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}

export function getPhotoContainers(url)
{
	return function (dispatch) {
		return axios.get('/api/photocontainers', {
			headers: headers
		})
		.then(function (response) {
			// handle success
			dispatch({type: "PHOTO_CONTAINERS", url: url, payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}

export function addStory(url, title, subTitle, titlePhoto, date, story, visible, order, hasGallery)
{
	return function (dispatch) {
		return axios.post('/api/insert', JSON.stringify({
	    url: url,
	    title: title,
			subTitle: subTitle,
			titlePhoto: titlePhoto,
			date: date,
			story: story,
			visible: visible ? 1 : 0,
			order: order,
			hasGallery: hasGallery ? 1 : 0
	  }), {
        withCredentials: true,
        headers: {"Content-Type": "application/json"}
    })
		.then(function (response) {
			// handle success
			dispatch({type: "STORY_ADD_SUCCESS", url: url, payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}

export function updateStory(url, title, subTitle, titlePhoto, date, story, visible, order, hasGallery)
{
	return function (dispatch) {
		return axios.post('/api/update', JSON.stringify({
	    url: url,
	    title: title,
			subTitle: subTitle,
			titlePhoto: titlePhoto,
			date: date,
			story: story,
			visible: visible ? 1 : 0,
			order: order,
			hasGallery: hasGallery ? 1 : 0
	  }), {
        withCredentials: true,
        headers: {"Content-Type": "application/json"}
    })
		.then(function (response) {
			// handle success
			dispatch({type: "STORY_UPDATE_SUCCESS", url: url, payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}

export function updatePostPhotos(photoObj)
{
	return function (dispatch) {
		return axios.post('/api/updatephotos', JSON.stringify({
	    photos: photoObj.photos
	  }), {
        withCredentials: true,
        headers: {"Content-Type": "application/json"}
    })
		.then(function (response) {
			// handle success
			dispatch({type: "PHOTOS_UPDATE_SUCCESS", url: photoObj.url, payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}

export function setTitlePhoto(url, filename)
{
	return function (dispatch) {
		return axios.post('/api/settitlephoto', JSON.stringify({
	    url: url,
	    filename: filename
	  }), {
        withCredentials: true,
        headers: {"Content-Type": "application/json"}
    })
		.then(function (response) {
			// handle success
			dispatch({type: "SET_TITLE_PHOTO_SUCCESS", url: url, payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}

export function updateTags(url, tags)
{
	return function (dispatch) {
		return axios.post('/api/updatetags', JSON.stringify({
	    url: url,
	    tags: tags
	  }), {
        withCredentials: true,
        headers: {"Content-Type": "application/json"}
    })
		.then(function (response) {
			// handle success
			dispatch({type: "TAGS_UPDATE_SUCCESS", url: url, payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}

export function updateContent(url, json)
{
	return function (dispatch) {
		return axios.post('/api/updatecontent', JSON.stringify({
	    url: url,
	    content: json
	  }), {
        withCredentials: true,
        headers: {"Content-Type": "application/json"}
    })
		.then(function (response) {
			// handle success
			dispatch({type: "CONTENT_UPDATE_SUCCESS", url: url, payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}

export function getPhotoElements(url) {
	return function (dispatch) {
		return axios.get(`/api/photoelements`, {
			headers: headers
		})
		.then(function (response) {
			// handle success
			dispatch({type: "PHOTO_ELEMENTS", url: url, payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}
