const defaultState = {
	albumCategories: []
};

const albumCategoryReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'ALBUM_CATEGORIES':
			return Object.assign({}, state, {
				"albumCategories": action.payload.data
			});

		default:
			return state
	}
}

export default albumCategoryReducer
