const defaultState = {
	photoGalleries: [],
	photoGalleryArray: [],
	photoGalleryPhotos: {}
};

const photoGalleryReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'PHOTO_GALLERIES':
			return Object.assign({}, state, {
				"photoGalleryArray": action.payload.data
			});
		case 'PHOTO_GALLERY':
			return Object.assign({}, state, {

				// We call different URL for each photo gallery.
				// Make sure not to overwrite data from any other gallery
				// when downloading the latest data.
				photoGallery:
				{
					...state.photoGalleryPhotos,
					[action.category_id]: action.payload.data
				}
			});
		default:
			return state
	}
}

export default photoGalleryReducer
