import React, { Component } from "react";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPhotoContainers } from '../actions';
import ChooseEditPostHome from "./ChooseEditPostHome";
import EditPost from "./EditPost";

class ChooseEditPost extends Component {
	 constructor(props) {
			super(props);
			this.state = {
				photoContainer: '',
				submit: false
			};
			this.handleChoosePhotoContainer = this.handleChoosePhotoContainer.bind(this);
	 }

	componentDidMount()
	{
		this.props.getPhotoContainers().then(data => {
 		 console.log("Success");
 		 console.log(data);
 	 }).catch(err => {
 		 console.log("err");
 		 console.log(err);
 	 });
	}

	handleChoosePhotoContainer()
	{
		this.setState({'submit': true});
	}

 	render()
	{
		const allPhotoContainers = this.props.photoContainerArray ? this.props.photoContainerArray : [];
		console.log(allPhotoContainers);
		console.log(this.props)

		return (
				<Routes>
					<Route path="" element={<ChooseEditPostHome />} />
					<Route path=":url" element={<EditPost />} />
				</Routes>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		photoContainerArray: state.photoContainerReducer.photoContainerArray
	}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			getPhotoContainers: getPhotoContainers
		}, dispatch);

}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseEditPost)
