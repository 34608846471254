const defaultState = {
	authors: []
};

const authorReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'AUTHORS':
			return Object.assign({}, state, {
				"authors": action.payload.data
			});

		default:
			return state
	}
}

export default authorReducer
