const defaultState = {
	photoElements: []
};

const photoElementReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'PHOTO_ELEMENTS':
			return Object.assign({}, state, {
				"photoElements": action.payload.data
			});

		default:
			return state
	}
}

export default photoElementReducer
