const defaultState = {
	photoStories: [],
	photoStoryArray: [],
	photoStory: {}
};

const photoStoryReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'PHOTO_STORIES':
			return Object.assign({}, state, {
				"photoStoryArray": action.payload.data
			});
		case 'PHOTO_STORY':
			return Object.assign({}, state, {
				// We call different URL for each photo gallery.
				// Make sure not to overwrite data from any other gallery
				// when downloading the latest data.
				photoStory:
				{
					[action.url]: action.payload.data
				}
			});
		default:
			return state
	}
}

export default photoStoryReducer
