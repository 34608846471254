import React, { Component  } from 'react';

class Footer extends Component
{
	constructor(props) {
		 super(props);
		 this.state = {};
	}

	render ()
	{
		const currentYear = new Date().getFullYear();
		return (
			<div className={'footer' + (this.props.isHomePage ? ' homePageFooter' : '')}>
				&copy; Zion Orent Photography {currentYear}
				&nbsp;&nbsp;
				<a href="https://www.instagram.com/friendofbearsandmoose/" target="top">
						<img src="siteimages/ig.svg" style={{'width': '18px'}} />
				</a>
			</div>
		);
	}
}

export default Footer
