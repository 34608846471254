const homePageReducer = (state = {}, action) => {
	switch (action.type) {
		case 'HOME_PAGE':
			return Object.assign({}, state, {"homePage": action.payload.data})
		default:
			return state
	}
}

export default homePageReducer
