import axios from "axios";

const headers = {
	'Access-Control-Allow-Origin': '*',
	'Cache-Control': 'max-age=100'
};

const headersNoCache = {
	'Access-Control-Allow-Origin': '*',
	'Cache-Control': 'no-store'
};

export function getHomePage() {
	return function (dispatch) {
		return axios.get('/api/home', {
			headers: headers
		})
		.then(function (response) {
			// handle success
			dispatch({type: "HOME_PAGE", payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}

export function getPhotoBooks() {
 return function (dispatch) {
	 return axios.get('/api/photobooks', {
			headers: headersNoCache
		})
		 .then(function (response) {
			 // handle success
				dispatch({type: "PHOTO_BOOKS", payload: response});
		 })
		 .catch(function (error) {
			 // handle error
			 return new Promise(function(resolve, reject) {
 					reject(error);
 			});
		 });
 };
}

export function getPhotoGalleries() {
	return function (dispatch) {
		return axios.get('/api/photogalleries', {
			headers: headersNoCache
		})
		.then(function (response) {
			// handle success
			dispatch({type: "PHOTO_GALLERIES", payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}

export function getPhotoGallery(category_id) {
	return function (dispatch) {
		return axios.get(`/api/photogallery?category_id=${category_id}`, {
			headers: headers
		})
		.then(function (response) {
			// handle success
			dispatch({type: "PHOTO_GALLERY", category_id: category_id, payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}

export function getPhotoStories() {
	return function (dispatch) {
		return axios.get('/api/photostories', {
			headers: headersNoCache
		})
		.then(function (response) {
			// handle success
			dispatch({type: "PHOTO_STORIES", payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}

export function getPhotoStory(url, alwaysGetPhotos, keepPostUrlShortcuts, getPhotoJson) {
	return function (dispatch) {
		return axios.get(`/api/photostory?url=${url}&alwaysGetPhotos=${alwaysGetPhotos}&keepPostUrlShortcuts=${keepPostUrlShortcuts}&getPhotoJson=${getPhotoJson}`, {
			headers: headersNoCache
		})
		.then(function (response) {
			// handle success
			dispatch({type: "PHOTO_STORY", url: url, payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}

export function getTags(url) {
	return function (dispatch) {
		return axios.get(`/api/tags`, {
			headers: headers
		})
		.then(function (response) {
			// handle success
			dispatch({type: "PHOTO_CONTAINER_TAGS", url: url, payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}

export function getAuthors() {
	return function (dispatch) {
		return axios.get(`/api/authors`, {
			headers: headers
		})
		.then(function (response) {
			// handle success
			dispatch({type: "AUTHORS", payload: response});
		})
		.catch(function (error) {
			// handle error
			return new Promise(function(resolve, reject) {
				reject(error);
			});
		});
	};
}

export const openHamburger = () => ({ type: "HAMBURGER_OPEN" });
export const closeHamburger = () => ({ type: "HAMBURGER_CLOSE" });
export const toggleHamburger = () => ({ type: "HAMBURGER_TOGGLE" });
