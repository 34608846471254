import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../lib/withRouter";
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { getPhotoGallery, getPhotoGalleries } from '../actions';
import PigsInSpace from "./PigsInSpace";
import PhotoGalleryContainer from '../containers/PhotoGalleryContainer';

class PhotoGallery extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		const id = this.getId();
		if (id)
		{
			this.props.getPhotoGallery(id).then(data => {
				console.log("Success");
				console.log(data);
			}).catch(err => {
				console.log("err");
				console.log(err);
			})
		}
	}

	componentDidUpdate(prevProps, prevState)
	{
	}

	getId()
	{
		return this.props.router && this.props.router.params ? this.props.router.params.id : null;
	}

 	render() {
		const id = this.getId();
		const photoGalleryArr = this.props.photoGalleryArray.filter(photoGallery => photoGallery.id === id);
		//const title = photoGalleryArr && photoGalleryArr[0] ? photoGalleryArr[0].title : id;
		//const description = photoGalleryArr && photoGalleryArr[0] ? photoGalleryArr[0].description : '';
		let parentStoryURL = this.props.parentUrl ? this.props.parentUrl : '';
		//parentStoryURL = (photoGalleryArr && photoGalleryArr[0] ? photoGalleryArr[0].parentUrl : '');

		const notFound = this.props.photoGallery && (id in this.props.photoGallery) && this.props.photoGallery[id] === undefined;
		let title = '';
		let description = '';
		let parentUrl = ''
		if (this.props.photoGallery && this.props.photoGallery[id])
		{
			title = this.props.photoGallery[id].title;
			description = this.props.photoGallery[id].subTitle;
			if (this.props.photoGallery[id].parentUrl)
				parentUrl = "/photostories/" + this.props.photoGallery[id].parentUrl;
		}
		const hasParentUrl = !!parentUrl;

		return (
				id ?
					<span>
					{notFound ?
					<PigsInSpace /> :
					<div>
						<div className="photoTitle">{title}</div>
						{
							description ?
							<article className="regText" dangerouslySetInnerHTML={{ __html: description }} />
							: ''
						}
						{
							(hasParentUrl && parentUrl) ?
							<div className="alignLeft" style={{color: 'blue'}}>
								<Link to={parentUrl}>
									<a className="blue-link">
										Back to parent
									</a>
								</Link>
							</div>
							: ''
						}
						<div className="regText">
							{
								this.props.photoGallery && this.props.photoGallery[id] && this.props.photoGallery[id].photos &&
								this.props.photoGallery[id].photos.length > 0 ?
								<PhotoGalleryContainer photos={this.props.photoGallery[id].photos}>
								</PhotoGalleryContainer>
								: ''
							}
						</div>
					</div>
					}
					</span>
				: ''
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const parentUrl = ownProps && ownProps.location && ownProps.location.state && ownProps.location.state.parentUrl ? ownProps.location.state.parentUrl : '';
	return {
		photoGallery: state.photoGalleryReducer.photoGallery,
		photoGalleryArray: state.photoGalleryReducer.photoGalleryArray,
		parentUrl: parentUrl
	}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			getPhotoGallery: getPhotoGallery,
			getPhotoGalleries: getPhotoGalleries
		}, dispatch);
}

export default compose(
	withRouter, connect(mapStateToProps, mapDispatchToProps)
)(PhotoGallery);
