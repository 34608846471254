import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPhotoGalleries, closeHamburger } from '../actions';

class About extends Component {
	 constructor(props) {
			super(props);
			this.state = {};
	 }

	componentDidMount()
	{
	}

 	render() {
		return (
			<article className="about-container">
				<div className="photoTitle">About me</div>

				<img src="/images/about/portraits_of_zion_by_emily_20170212_26.jpg" className="floatRight" />
				<p className="regText alignLeft">
					During the day I do computer stuffs. The rest of the time I'm trying to find time for a variety of photo projects, including documentary photography, events, digital darkroom projects, and photo books.
				</p>

				<p className="regText alignLeft">
					I studied at the New England School of Photography in Boston. In some ways it feels like I never left, as I continue to build on what I learned there and on the awesome experiences with the awesome people there.
				</p>

				<p className="regText alignLeft">
					My e-mail address is Zion Orent Photography&mdash; no periods or spaces&mdash; at gmail.
				</p>

				<p className="regText alignLeft">
					To see some of my computer projects, please visit <a href="https://orent.info/" class="blog" target="top">orent.info</a>.
				</p>

			</article>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
		return {}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			closeHamburger: closeHamburger
		}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(About)
