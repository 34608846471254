import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPhotoGalleries, closeHamburger } from '../actions';

class PigsInSpace extends Component {
	 constructor(props) {
			super(props);
			this.state = {};
	 }

	componentDidMount()
	{
	}

 	render() {
		return (
			<article className="blogpost">
				<div className="articleTitle">Looks like you're looking for a page which doesn't exist</div>

				<p className="regText alignLeft">
					Welcome to the 404 page!
				</p>

				<p className="regText alignLeft">
					Sorry that you haven't found what you're looking for.
				</p>

				<p className="regText alignLeft">
					At one point, this site got completely rebuilt. Unfortunately, older links don't work any more.
				</p>

				<p className="regText alignLeft">
					Hope you find what you're seeking really soon!
				</p>

				{/*
				<p className="regText alignLeft">
					<Link to="/about" onClick={this.props.closeHamburger}>Home</Link>
				</p>
				*/}

				<figure className="large">
				    <img src="/images/404/NESOP_artdesign_20121118_0050.jpg" />
						<figcaption>
								Silver Spring, MD
						</figcaption>
				</figure>

			</article>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
		return {}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
			closeHamburger: closeHamburger
		}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PigsInSpace)
